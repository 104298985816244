import React, { FC, useCallback, useMemo } from "react"
import _ from "lodash"
import { Row, Col } from "antd"

import { useCart } from "../../context/CartContext"
import { Service } from "../../models/service"

import ServiceItem from "./item"

export const ServicesSelection: FC<{
  services: Service[]
  category: string
}> = ({ services, category }) => {
  const cart = useCart()
  const lineItems = cart.lineItems

  const filteredServices = useMemo(() => {
    return category !== "all" ? _.filter(services, { category }) : services
  }, [category])

  const handleOnAdd = useCallback((id: string, quantity: number) => {
    const newAddedService = _.find(services, { id })
    cart.addToCart(newAddedService, quantity)
  }, [])

  return (
    <Row
      justify="center"
      gutter={[
        { xs: 0, sm: 0, md: 16, lg: 16 },
        { xs: 16, sm: 16, md: 16, lg: 16 },
      ]}
      style={{ width: "100%" }}
    >
      {_.sortBy(filteredServices, "price")
        .reverse()
        .map(service => {
          return (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} key={service.id}>
              <ServiceItem
                service={service}
                onSelect={handleOnAdd}
                isSelected={
                  _.findIndex(
                    lineItems,
                    lineItem => lineItem.item.id === service.id
                  ) !== -1
                }
              />
            </Col>
          )
        })}
    </Row>
  )
}
