import React, { FC, useCallback, useState } from "react"
import {
  Card,
  Space,
  Typography,
  Tag,
  Button,
  Row,
  Col,
  InputNumber,
} from "antd"
import _ from "lodash"
import { Heart } from "@styled-icons/entypo"
import { Check2Circle } from "@styled-icons/bootstrap/"

import { Service } from "../../models/service"
import { minToHourFormat } from "../../ultilities"

const { Title, Paragraph, Text } = Typography

const ServiceItem: FC<{
  service: Service
  onSelect: (id: string, quantity: number) => void
  isSelected: boolean
}> = ({ service, onSelect, isSelected }) => {
  const [quantity, setQuantity] = useState<number>(1)

  const handleAddToCart = useCallback(
    e => {
      const { id } = e.currentTarget.dataset
      onSelect && onSelect(id, quantity)
    },
    [quantity]
  )

  const handleOnQuantityChange = useCallback(value => {
    setQuantity(value)
  }, [])

  return (
    <Card hoverable>
      <Title level={4}>{service.name}</Title>

      <Row>
        <Col span={24}>
          <Tag color="#bd9d95">{_.startCase(service.category)}</Tag>
          {service.recommend_level && (
            <Space size="small">
              {service.recommend_level === "recommended" && (
                <>
                  <Heart size="14" style={{ color: "#5f001d" }} />
                  <Heart size="14" style={{ color: "#5f001d" }} />
                  <Heart size="14" style={{ color: "#5f001d" }} />
                  <Heart size="14" style={{ color: "#5f001d" }} />
                </>
              )}
            </Space>
          )}
        </Col>
      </Row>

      <Space size="large">
        {service.estimate_service_time > 0 && (
          <Text>
            Est Time:{" "}
            <strong className="text-secondary">
              {minToHourFormat(service.estimate_service_time)}
            </strong>{" "}
          </Text>
        )}
      </Space>

      <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
        {service.description}
      </Paragraph>

      {/* {!isSelected ? (
        <Row align="middle" justify="space-between">
          <Col span={16}>
            <Space>
              <Text>QTY</Text>
              <InputNumber
                size="small"
                min={1}
                max={20}
                defaultValue={1}
                onChange={handleOnQuantityChange}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Button
              block
              data-id={service.id}
              onClick={handleAddToCart}
              disabled={quantity < 0 || quantity === 0}
              type="primary"
              shape="round"
            >
              ADD
            </Button>
          </Col>
        </Row>
      ) : (
        <div style={{ color: "green", textAlign: "right" }}>
          <Check2Circle size="16" />{" "}
          <Text style={{ color: "green" }}>Added to Your Visit</Text>
        </div>
      )} */}
    </Card>
  )
}

export default ServiceItem
