import { useState, useCallback, useEffect } from "react";
import { fetchServices } from "../../../services/ic.service";
import { Service } from "../../../models/service";

type FetchServicesState = {
  loading: boolean;
  services: Service[];
  error: Error;
  reload?: () => void;
};

export default (): FetchServicesState => {
  const [loading, setLoading] = useState<boolean>(true);
  const [services, setServices] = useState<Service[]>();
  const [error, setError] = useState<Error>();

  const load = useCallback(() => {
    setLoading(true);
    fetchServices()
      .then((res) => {
        setServices(res.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return { loading, services, error, reload: load };
};
