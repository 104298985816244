import React, { FC, useCallback } from "react"
import _ from "lodash"
import { Typography, message, Divider, Spin, Affix, Badge } from "antd"
import { Formik } from "formik"
import * as Yup from "yup"
import { Form, Select } from "formik-antd"

import { ShoppingCart } from "@styled-icons/feather"

import { Service } from "../../models/service"
import { Technician } from "../../models/technician"

import useFetchServices from "../service/hooks/useFetchServices"
import useFetchTechnicians from "../technician/hooks/useFetchTechnicians"

import { ServicesSelection } from "../service/selection"
import Cart from "../cart"

import BottomNav from "../bottom-nav"

import { useCart } from "../../context/CartContext"

const { Title } = Typography

const { Option } = Select

const aptInfoSchema = Yup.object().shape({
  category: Yup.string(),
})

const initialValues = {
  category: "Manicures",
}

export default () => {
  const { loading, services, error } = useFetchServices()

  return loading ? (
    <Spin
      size="large"
      className="mx-auto"
      tip="LOADING..."
      style={{ display: "block", marginLeft: "auto" }}
    />
  ) : (
    <>
      {error ? message.error(error.message) : <Booking services={services} />}
    </>
  )
}

const Booking: FC<{
  services: Array<Service>
}> = ({ services }) => {
  // const cart = useCart()

  // const handleShowBookingDetail = useCallback(() => {
  //   cart.openCart()
  // }, [])

  const handleBookAppointment = useCallback(values => {
    console.log(values)
  }, [])

  return (
    <div style={{ backgroundColor: "white" }} className="px-2 py-2">
      <Title level={1}>OUR SERVICES</Title>

      <Formik
        initialValues={initialValues}
        validationSchema={aptInfoSchema}
        onSubmit={handleBookAppointment}
      >
        {({ values }) => {
          return (
            <Form>
              <Form.Item name="category">
                <div
                  style={{
                    backdropFilter: "saturate(180%) blur(20px)",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    padding: "5px",
                  }}
                  className="shadow-sm rounded"
                >
                  <Title level={5}>Filter Services by Category</Title>
                  <Select
                    name="category"
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {_.sortBy(
                      _.uniqBy(services, "category"),
                      "categoryOrder"
                    ).map(service => {
                      return (
                        <Option key={service.category} value={service.category}>
                          {_.toUpper(service.category)}
                        </Option>
                      )
                    })}
                  </Select>
                </div>
              </Form.Item>

              <div style={{ padding: "5px" }}>
                <Divider orientation="center">
                  <h2 className="text-primary">
                    {_.toUpper(values.category)} SERVICES
                  </h2>
                </Divider>

                {services.length > 0 ? (
                  <ServicesSelection
                    services={services}
                    category={values.category}
                  />
                ) : (
                  <p>NO SERVICE RECORD</p>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>

      {/* {cart.lineItems.length > 0 && (
        <BottomNav>
          <button
            onClick={handleShowBookingDetail}
            style={{
              backdropFilter: "saturate(180%) blur(20px)",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              border: "none",
            }}
          >
            <Badge count={cart.lineItems.length} overflowCount={9}>
              <ShoppingCart size="36" />
            </Badge>
          </button>
        </BottomNav>
      )}

      <Cart /> */}
    </div>
  )
}
